<script setup lang="ts">
import { storeToRefs } from 'pinia';
import ColorPickerButton from '~/components/pro/molecules/ColorPickerButton.vue';
import { useSettingsStore } from '~/stores/shared/settings';

const {
  primaryColor,
  grayTone,
  grayTones,
  primaryColors
} = storeToRefs(useSettingsStore());
</script>

<template>
  <div class="p-2">
    <div class="grid grid-cols-5 gap-px">
      <ColorPickerButton
        v-for="color in primaryColors"
        :key="color.value"
        :color="color"
        :selected="primaryColor"
        @select="primaryColor = color"
      />
    </div>

    <hr class="my-2 border-gray-200 dark:border-gray-800" />

    <div class="grid grid-cols-5 gap-px">
      <ColorPickerButton
        v-for="color in grayTones"
        :key="color.value"
        :color="color"
        :selected="grayTone"
        @select="grayTone = color"
      />
    </div>
  </div>
</template>
