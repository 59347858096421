<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '~/stores/shared/settings';
import ColorPicker from '~/components/pro/molecules/ColorPicker.vue';

const settingsStore = useSettingsStore();
const { colorMode } = storeToRefs(settingsStore);
</script>
<template>
  <UPopover>
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        square
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      >
        <UIcon
          name="i-heroicons-swatch-20-solid"
          class="text-primary-500 dark:text-primary-400 size-5"
        />
      </UButton>
    </template>

    <template #panel>
      <ColorPicker />
    </template>
  </UPopover>

  <UButton
    v-show="colorMode == 'dark'"
    variant="ghost"
    color="gray"
    icon="i-fluent-weather-moon-24-filled"
    @click="colorMode = 'light'"
  />
  <UButton
    v-show="colorMode == 'light'"
    variant="ghost"
    color="gray"
    icon="i-fluent-weather-sunny-24-filled"
    @click="colorMode = 'dark'"
  />
</template>
